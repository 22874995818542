"use strict";

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}
if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    var el = this;
    do {
      if (Element.prototype.matches.call(el, s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}
if ('objectFit' in document.documentElement.style === false) {
  document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('img[data-object-fit]').forEach(function (image) {
      (image.runtimeStyle || image.style).background = "url(\"".concat(image.src, "\") no-repeat 50%/").concat(image.currentStyle ? image.currentStyle['object-fit'] : image.getAttribute('data-object-fit'));
      image.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='".concat(image.width, "' height='").concat(image.height, "'%3E%3C/svg%3E");
    });
  });
}
document.addEventListener('DOMContentLoaded', function () {
  contactFormPlaceholder();
  getPhoneNumbers();

  //Header Option
  if (document.querySelector('#header')) {
    document.querySelector('#header').classList.add('normal'); //Choose Here Class Name (normal or fixed or intelligent);

    if (document.querySelector('#header') && document.querySelector('#header').classList.contains('intelligent')) {
      document.querySelector('#slider').classList.add('top-m');
    }
    if (document.querySelector('#slider') && document.querySelector('#slider').classList.contains('fixed')) {
      document.querySelector('#slider').classList.add('top-m');
    }
  }
  var headerHeight = getComputedStyle(document.querySelector('#header'), null).height;
  var st = window.scrollTo(0, 0);
  var stickOnScroll = function stickOnScroll() {
    if (document.querySelector('#header').classList.contains("intelligent")) {
      document.querySelector('#header').classList.remove('normal');
      document.querySelector('#slider').classList.add('top');
      var pos = window.scrollTo(0, 0);
      if (pos > headerHeight) {
        if (pos > st) {
          document.querySelector('#header').classList.add('simple');
          document.querySelector('#header.simple').classList.remove('down');
          document.querySelector('#header.simple').classList.add('fixed');
          document.querySelector('#header.simple').classList.add('up');
        } else {
          document.querySelector('#header.simple').classList.remove('up');
          document.querySelector('#header.simple').classList.add('fixed');
          document.querySelector('#header.simple').classList.add('down');
        }
        st = pos;
      } else {
        if (document.querySelector('#header.simple')) {
          document.querySelector('#header.simple').classList.remove('fixed');
          document.querySelector('#header.simple').classList.remove('down');
          document.querySelector('#header.simple').classList.remove('up');
          document.querySelector('#header.simple').classList.remove('simple');
        }
      }
      if (pos == getComputedStyle(document, null).height - getComputedStyle(window, null).height) {
        if (document.querySelector('#header.simple')) {
          document.querySelector('#header.simple').classList.remove('up');
          document.querySelector('#header.simple').classList.add('fixed');
          document.querySelector('#header.simple').classList.add('down');
        }
      }
    } else if (document.body.classList.contains("fix")) {
      document.querySelector('#slider').classList.add('top');
      document.querySelector('#header').classList.add('simple');
      document.querySelector('#header').classList.add('fixed');
      document.querySelector('#header').classList.remove('down');
      document.querySelector('#header').classList.remove('up');
      document.querySelector('#wrapper').style.paddingTop = 0;
    } else {
      if (document.querySelector('#header.simple')) {
        document.querySelector('#header.simple').classList.remove('fixed');
        document.querySelector('#header.simple').classList.remove('down');
        document.querySelector('#header.simple').classList.remove('up');
        document.querySelector('#header.simple').classList.remove('simple');
      }
      document.querySelector('#header').classList.add('normal');
      if (document.querySelector('#slider')) {
        document.querySelector('#slider').classList.remove('top');
      }
      document.querySelector('#wrapper').style.paddingTop = 0;
    }
  };
  stickOnScroll();
  window.addEventListener('scroll', function () {
    stickOnScroll();
  });
  if (document.querySelector('.footer-form')) {
    document.querySelector('.footer-form').addEventListener('submit', function (event) {
      event.preventDefault();
      var form = document.querySelector('.footer-form');
      document.querySelector('#btnContactSubmit').setAttribute("disabled", true);
      document.querySelector('#btnContactSubmit').classList.add('loading');
      document.querySelector('#formContactAlert').style.display = 'none';
      var formData = new FormData();
      var data = {
        'name': encodeURIComponent(document.querySelector('[name="contact-name"]').value),
        'email': encodeURIComponent(document.querySelector('[name="contact-email"]').value),
        'message': encodeURIComponent(document.querySelector('[name="contact-message"]').value)
      };
      formData.append('form', 'contact-name=' + data.name + '&contact-email=' + data.email + '&contact-message=' + data.message);
      formData.append('language', document.querySelector('html').getAttribute('lang'));
      formData.append('token', 'imnotarobot');
      var req = new XMLHttpRequest();
      req.open('POST', form.getAttribute('action'), true);
      req.send(formData);
      req.onreadystatechange = function () {
        if (req.readyState === 4) {
          if (req.status === 200) {
            var response = JSON.parse(req.responseText);
            if (typeof response.sent === "undefined" || response.sent === false) {
              document.querySelector('#formContactAlert').style.display = '';
              document.querySelector('#formContactAlert').innerHTML = translations['contact form error'];
              if (response.validMail === false) {
                document.querySelector('#formContactAlert').innerHTML = translations['contact form invalid email'];
              }
              document.querySelector('#btnContactSubmit').removeAttribute('disabled');
            } else {
              form.querySelector('input, textarea').value = '';
              document.querySelector('#formContactAlert').style.display = '';
              form.querySelector('input, textarea').classList.remove('with-success');
              document.querySelector('#formContactAlert').innerHTML = translations['contact form success'];
              document.querySelector('#btnContactSubmit').classList.add('sent');
              form.reset();
              setTimeout(function () {
                document.querySelector('#btnContactSubmit').innerHTML = document.querySelector('#btnContactSubmit').dataset.text;
                document.querySelector('#btnContactSubmit').removeAttribute('disabled');
                document.querySelector('#btnContactSubmit').classList.remove('sent');
              }, 10000);
              event.target.querySelectorAll('.field label').forEach(function (label) {
                label.classList.remove('hidden');
              });
            }
            document.querySelector('#btnContactSubmit').classList.remove('loading');
          } else {
            document.querySelector('#formContactAlert').style.display = '';
            document.querySelector('#formContactAlert').innerHTML = translations['contact form error'];
            document.querySelector('#btnContactSubmit').removeAttribute("disabled");
            document.querySelector('#btnContactSubmit').classList.remove('loading');
          }
        }
      };
      return true;
    });
  }

  // end for sticky header
});
function contactFormPlaceholder() {
  if (document.querySelector('.footer-info-text')) {
    var inputs = document.querySelectorAll('.footer-info-text');
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('input', function () {
        var label = this.closest('.field').querySelector('label');
        if (this.value.length > 0) {
          if (!label.classList.contains('hidden')) {
            label.classList.add('hidden');
          }
        } else {
          label.classList.remove('hidden');
        }
      });
    }
  }
}

// for SEO
// add class "defer-img" and data-src="src" to defer image, src set to "defer.png"
function deferImages() {
  var imgDefer = document.querySelectorAll('img.defer-img');
  if (imgDefer.length > 0) {
    var src = '';
    for (var i = 0; i < imgDefer.length; i++) {
      src = imgDefer[i].getAttribute('data-src');
      imgDefer[i].setAttribute('src', src);
    }
  }
}
function send_to_logger(data) {
  var xhr = new XMLHttpRequest();
  xhr.open('POST', '/logger.php', true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhr.send("data=".concat(data));
}
function getPhoneNumbers() {
  var formData = new FormData();
  formData.append('language', document.querySelector('html').getAttribute('lang'));
  var xhr = new XMLHttpRequest();
  xhr.open('POST', '//' + window.location.hostname + '/get-phone-number', true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhr.send("language=".concat(document.querySelector('html').getAttribute('lang')));
  xhr.onreadystatechange = function (e) {
    if (this.readyState === 4) {
      if (this.status === 200) {
        var data = JSON.parse(this.responseText);
        if (data.status) {
          showPagePhone(data.numbers);
        }
      } else {
        send_to_logger("JSERR " + window.location.href + "main.js:getPhoneNumbers(): Phone numbers access has been failed");
      }
    }
  };
}
function showPagePhone(phoneNumbers) {
  if (typeof phoneNumbers[0] !== "undefined") {
    var primaryPhone = phoneNumbers[0],
      numberInHtml = "<b>" + primaryPhone.number + "</b> (" + primaryPhone.title + ")";
    if (document.querySelector('#header .contact-number')) {
      document.querySelector('#header .contact-number').setAttribute('href', 'tel:' + primaryPhone.raw_number);
    }
    if (document.querySelector('.top-phone-link')) {
      document.querySelector('.top-phone-link').setAttribute('href', 'tel:' + primaryPhone.raw_number);
    }
    if (document.querySelector('.contact-number-placeholder')) {
      document.querySelector('.contact-number-placeholder').setAttribute('href', 'tel:' + primaryPhone.raw_number);
    }
    if (document.querySelector('.number-placeholder')) {
      for (var i = 0; i < document.querySelectorAll('.number-placeholder').length; i++) {
        document.querySelectorAll('.number-placeholder')[i].innerHTML = numberInHtml;
      }
    }
  }
}
var mainBackground = document.querySelector('.top-background');
function adjustMainBackground() {
  mainBackground.style.height = getComputedStyle(document.querySelector('.top-img'), null).height;
}
if (mainBackground) {
  adjustMainBackground();
  window.addEventListener('resize', function () {
    adjustMainBackground();
  });
}
var navbarButton = document.querySelector('.navbar-toggle');
var navbar = document.querySelector('#expandable-navbar-1');
function calcNavbarHeight() {
  if (navbar) {
    var expanded = getComputedStyle(navbar, null).height !== '0px';
    navbar.style.position = 'fixed';
    navbar.style.left = '5000px';
    navbar.style.height = 'auto';
    navbar.dataset.height = getComputedStyle(navbar, null).height;
    navbar.removeAttribute('style');
    if (expanded) {
      navbar.style.height = navbar.dataset.height;
    } else {
      navbar.style.height = '0';
    }
  }
}
window.addEventListener('resize', function () {
  calcNavbarHeight();
});
window.addEventListener('load', function () {
  if (navbarButton) {
    calcNavbarHeight();
    navbarButton.addEventListener('click', function () {
      var expanded = getComputedStyle(navbar, null).height !== '0px';
      navbar.setAttribute('aria-expanded', navbar.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
      // calcNavbarHeight();
      if (expanded) {
        navbar.style.height = '0px';
      } else {
        navbar.style.height = navbar.dataset.height;
      }
    });
  }
});
function getTranslationsFromLang() {
  var currLang = document.querySelector('body').getAttribute('data-current-lang');
  var ajaxParams = [];
  for (var i = 0; i < arguments.length; i++) {
    ajaxParams.push([arguments[i]]);
  }
  var formData = 'params=[' + ajaxParams.map(function (key) {
    return "[\"".concat(key, "\", \"").concat(currLang, "\"]");
  }).join(',').replace(/%20/g, '+') + ']';
  var xhr = new XMLHttpRequest();
  xhr.open('POST', '//' + window.location.hostname + '/langTranslation', false);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhr.send(formData);
  if (xhr.status === 200) {
    return JSON.parse(xhr.responseText);
  }
}
var translations = getTranslationsFromLang('contact form error', 'contact form invalid email', 'contact form success');